import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, Navbar, Container } from "react-bootstrap";
import ResultsContainer from "./containers/ResultsContainer";
import NoResultsContainer from "./containers/NoResultsContainer";
import fetchEventService from "./services/eventServices";
import "./App.scss";

const eventId = window.location.pathname.split("/")?.[1];

const handleDeclineCookies = () => {
  window["ga-disable-G-JJXTZJW9DQx"] = true;
};

function App() {
  const [eventData, setEventData] = useState();
  const [eventBackground, setEventBackground] = useState(
    "defaultBackground.jpg"
  );

  const fetchEventInfo = async (eventId) => {
    const eventInfo = await fetchEventService(eventId);
    setEventData(eventInfo?.data);
  };

  useEffect(() => {
    if (eventId) {
      fetchEventInfo(eventId);
    }
  }, []);

  useEffect(() => {
    if (eventData?.eventAwardsPresentationBackground) {
      setEventBackground(eventData.eventAwardsPresentationBackground);
    }
  }, [eventData]);

  return (
    <ThemeProvider
      breakpoints={["xl", "lg", "md", "sm", "xs"]}
      minBreakpoint="xxs"
    >
      <div
        style={{
          height: "100%",
          background: `url('${process.env.REACT_APP_IMAGES_URL}/awardsBackground/${eventBackground}') no-repeat center center fixed`,
        }}
      >
        <Navbar className="top-navbar" bg="light" sticky="top" expand="lg">
          <Container>
            <Navbar.Brand href="#" className="navbar-logo-a">
              {eventData && (
                <img
                  src={`${process.env.REACT_APP_IMAGES_URL}/logos/${eventData.eventLogo}`}
                  className="d-inline-block align-top navbar-logo"
                  height="50"
                  alt={eventData.eventName}
                />
              )}
            </Navbar.Brand>
          </Container>
        </Navbar>
        <Routes>
          <Route path="/" element={<NoResultsContainer />}></Route>
          <Route path="/:eventId" element={<ResultsContainer />}></Route>
        </Routes>
        <CookieConsent enableDeclineButton onDecline={handleDeclineCookies}>
          This website uses cookies to ensure you get the best experience on our
          website.
        </CookieConsent>
        <footer className="footer">
          <p>Copyright © MYLAPS Event Timing</p>
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default App;
